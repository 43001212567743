import React from "react";
import Evolutions from "./evos/Evolutions";

const EvolutionsWrapper = () => {
  return (
    <div>
      <Evolutions />
    </div>
  );
};

export default EvolutionsWrapper;
