export const SBC_REQUIREMENTS = {
  "sbc.requirements.bronzePlayers": "Bronze Players Only",
  "sbc.requirements.challenge": "Challenge Requirements",
  "sbc.requirements.challenge.condensed": "Requirements",
  "sbc.requirements.clubs.scope0": "Clubs in Squad: Min. %1",
  "sbc.requirements.clubs.scope1": "Clubs in Squad: Max. %1",
  "sbc.requirements.clubs.scope2": "Clubs in Squad: Exactly %1",
  "sbc.requirements.combined.team.chemistry-points.scope0":
    "Min. %1 Squad Total Chemistry Points",
  "sbc.requirements.combined.team.chemistry-points.scope1":
    "Max. %1 Squad Total Chemistry Points",
  "sbc.requirements.combined.team.chemistry-points.scope2":
    "Exactly %1 Squad Total Chemistry Points",
  "sbc.requirements.combined.team.player-chemistry-points.scope0":
    "Min. %1 Chemistry Points on Each Player",
  "sbc.requirements.combined.team.player-chemistry-points.scope1":
    "Max. %1 Chemistry Points on Each Player",
  "sbc.requirements.combined.team.player-chemistry-points.scope2":
    "Exactly %1 Chemistry Points on Each Player",
  "sbc.requirements.firstowner.scope0": "First Owned Players: Min. %1",
  "sbc.requirements.firstowner.scope1": "First Owned Players: Max. %1",
  "sbc.requirements.firstowner.scope2": "First Owned Players: Exact %1",
  "sbc.requirements.fraction": "%1/%2",
  "sbc.requirements.goldPlayers": "Gold Players Only",
  "sbc.requirements.leagues.scope0": "Leagues in Squad: Min. %1",
  "sbc.requirements.leagues.scope1": "Leagues in Squad: Max. %1",
  "sbc.requirements.leagues.scope2": "Leagues in Squad: Exactly %1",
  "sbc.requirements.legend.scope0": "Icon Player: Min. %1",
  "sbc.requirements.legend.scope1": "Icon Player: Max. %1",
  "sbc.requirements.legend.scope2": "Icon Player: Exactly %1",
  "sbc.requirements.legends.scope0": "Icon Players: Min. %1",
  "sbc.requirements.legends.scope1": "Icon Players: Max. %1",
  "sbc.requirements.legends.scope2": "Icon Players: Exactly %1",
  "sbc.requirements.nations.scope0": "Nationalities in Squad: Min. %1",
  "sbc.requirements.nations.scope1": "Nationalities in Squad: Max. %1",
  "sbc.requirements.nations.scope2": "Nationalities in Squad: Exactly %1",
  "sbc.requirements.numplayers": "Number of Players in the Squad:",
  "sbc.requirements.player.scope0": "Player from the same %2: Min. %1",
  "sbc.requirements.player.scope0.val": "Min. %1 Player",
  "sbc.requirements.player.scope1": "Player from the same %2: Max. %1",
  "sbc.requirements.player.scope1.val": "Max. %1 Player",
  "sbc.requirements.player.scope2.val": "Exactly %1 Player",
  "sbc.requirements.playerQuality": "Player Quality:",
  "sbc.requirements.players.scope0": "Players from the same %2: Min. %1",
  "sbc.requirements.players.scope0.val": "Min. %1 Players",
  "sbc.requirements.players.scope1": "Players from the same %2: Max %1",
  "sbc.requirements.players.scope1.val": "Max. %1 Players",
  "sbc.requirements.players.scope2": "Players from the same %2: Exactly %1",
  "sbc.requirements.players.scope2.val": "Exactly %1 Players",
  "sbc.requirements.rare.scope0": "Min. %1 Players",
  "sbc.requirements.rare.scope1": "Max. %1 Players",
  "sbc.requirements.rare.scope2": "Exactly %1 Players",
  "sbc.requirements.rating.exact.scope0":
    "Players with exact OVR of %2: Min. %1",
  "sbc.requirements.rating.exact.scope1":
    "Players with exact OVR of %2: Max. %1",
  "sbc.requirements.rating.exact.scope2":
    "Players with exact OVR of %2: Exactly %1",
  "sbc.requirements.rating.exact.val": "Exact %1 OVR",
  "sbc.requirements.rating.max.scope0":
    "Players with maximum OVR of %2: Min. %1",
  "sbc.requirements.rating.max.scope1":
    "Players with maximum OVR of %2: Max. %1",
  "sbc.requirements.rating.max.scope2":
    "Players with maximum OVR of %2: Exactly %1",
  "sbc.requirements.rating.max.val": "Max. %1 OVR",
  "sbc.requirements.rating.min.scope0":
    "Players with minimum OVR of %2: Min. %1",
  "sbc.requirements.rating.min.scope1":
    "Players with minimum OVR of %2: Max. %1",
  "sbc.requirements.rating.min.scope2":
    "Players with minimum OVR of %2: Exactly %1",
  "sbc.requirements.rating.min.val": "Min. %1 OVR",
  "sbc.requirements.scope0": "Min.",
  "sbc.requirements.scope1": "Max.",
  "sbc.requirements.scope2": "Exactly",
  "sbc.requirements.silverPlayers": "Silver Players Only",
  "sbc.requirements.subType.club": "Club",
  "sbc.requirements.subType.league": "League",
  "sbc.requirements.subType.nation": "Nation",
  "sbc.requirements.team.chemistry-points.scope0": "Total Chemistry: Min. %1",
  "sbc.requirements.team.chemistry-points.scope1": "Total Chemistry: Max. %1",
  "sbc.requirements.team.chemistry-points.scope2":
    "Total Chemistry: Exactly %1",
  "sbc.requirements.team.chemistry.scope0": "Team Chemistry: Min. %1",
  "sbc.requirements.team.chemistry.scope1": "Team Chemistry: Max. %1",
  "sbc.requirements.team.chemistry.scope2": "Team Chemistry: Exactly %1",
  "sbc.requirements.team.player-chemistry-points.scope0":
    "Chemistry Points on Each Player: Min. %1",
  "sbc.requirements.team.player-chemistry-points.scope1":
    "Chemistry Points on Each Player: Max. %1",
  "sbc.requirements.team.player-chemistry-points.scope2":
    "Chemistry Points on Each Player: Exactly %1",
  "sbc.requirements.team.rating.scope0": "Team Rating: Min. %1",
  "sbc.requirements.team.rating.scope1": "Team Rating: Max. %1",
  "sbc.requirements.team.rating.scope2": "Team Rating: Exactly %1",
  "sbc.requirements.team.rating1to100.scope0": "Team Rating: Min. %1",
  "sbc.requirements.team.rating1to100.scope1": "Team Rating: Max. %1",
  "sbc.requirements.team.rating1to100.scope2": "Team Rating: Exactly %1",
  "sbc.requirements.tradable.scope0": "Tradable Players: Min. %1",
  "sbc.requirements.tradable.scope1": "Tradable Players: Max. %1",
  "sbc.requirements.tradable.scope2": "Tradable Players: Exact %1",
  "sbc.requirements.untradable.scope0": "Untradeable Players: Min. %1",
  "sbc.requirements.untradable.scope1": "Untradeable Players: Max. %1",
  "sbc.requirements.untradable.scope2": "Untradeable Players: Exact %1",
};

export const ACADEMY_REQUIREMENTS = {
  "academy.requirements.player.acceleration.scope0": "Acceleration Min: %1",
  "academy.requirements.player.acceleration.scope1": "Acceleration Max: %1",
  "academy.requirements.player.acceleration.scope2": "Acceleration Exactly: %1",
  "academy.requirements.player.aggression.scope0": "Aggression Min: %1",
  "academy.requirements.player.aggression.scope1": "Aggression Max: %1",
  "academy.requirements.player.aggression.scope2": "Aggression Exactly: %1",
  "academy.requirements.player.agility.scope0": "Agility Min: %1",
  "academy.requirements.player.agility.scope1": "Agility Max: %1",
  "academy.requirements.player.agility.scope2": "Agility Exactly: %1",
  "academy.requirements.player.alt_positions_count.scope0":
    "Total Positions Min: %1",
  "academy.requirements.player.alt_positions_count.scope1":
    "Total Positions Max: %1",
  "academy.requirements.player.alt_positions_count.scope2":
    "Total Positions Equals: %1",
  "academy.requirements.player.ball_control.scope0": "Ball Control Min: %1",
  "academy.requirements.player.balance.scope0": "Balance Min: %1",
  "academy.requirements.player.balance.scope1": "Balance Max: %1",
  "academy.requirements.player.balance.scope2": "Balance Exactly: %1",
  "academy.requirements.player.max-roles-plus-plus.scope0": "Min Roles++ :%1",
  "academy.requirements.player.max-roles-plus-plus.scope1": "Max Roles++ :%1",
  "academy.requirements.player.max-roles-plus-plus.scope2":
    "Exactly Roles++ :%1",
  "academy.requirements.player.max-roles-plus.scope0": "Min Roles+ : %1",
  "academy.requirements.player.max-roles-plus.scope1": "Max Roles+ : %1",
  "academy.requirements.player.max-roles-plus.scope2": "Exactly Roles+ : %1",
  "academy.requirements.player.ball_control.scope1": "Ball Control Max: %1",
  "academy.requirements.player.ball_control.scope2": "Ball Control Exactly: %1",
  "academy.requirements.player.base-traits-count.scope0":
    "# of PlayStyle Min: %1",
  "academy.requirements.player.base-traits-count.scope1":
    "# of PlayStyle Max: %1",
  "academy.requirements.player.base-traits-count.scope2":
    "# of PlayStyle Equals: %1",
  "academy.requirements.player.base-traits-exists": "PlayStyle: %1",
  "academy.requirements.player.club.label": "Club:",
  "academy.requirements.player.composure.scope0": "Composure Min: %1",
  "academy.requirements.player.composure.scope1": "Composure Max: %1",
  "academy.requirements.player.composure.scope2": "Composure Exactly: %1",
  "academy.requirements.player.crossing.scope0": "Crossing Min: %1",
  "academy.requirements.player.crossing.scope1": "Crossing Max: %1",
  "academy.requirements.player.crossing.scope2": "Crossing Exactly: %1",
  "academy.requirements.player.curve.scope0": "Curve Min: %1",
  "academy.requirements.player.curve.scope1": "Curve Max: %1",
  "academy.requirements.player.curve.scope2": "Curve Exactly: %1",
  "academy.requirements.player.def_awareness.scope0":
    "Defense Awareness Min: %1",
  "academy.requirements.player.def_awareness.scope1":
    "Defense Awareness Max: %1",
  "academy.requirements.player.def_awareness.scope2":
    "Defense Awareness Exactly: %1",
  "academy.requirements.player.defense.scope0": "Defense Min: %1",
  "academy.requirements.player.defense.scope1": "Defense Max: %1",
  "academy.requirements.player.defense.scope2": "Defense Exactly: %1",
  "academy.requirements.player.dribbling_main.scope0": "Dribbling Min: %1",
  "academy.requirements.player.dribbling_main.scope1": "Dribbling Max: %1",
  "academy.requirements.player.dribbling_main.scope2": "Dribbling Exactly: %1",
  "academy.requirements.player.finishing.scope0": "Finishing Min: %1",
  "academy.requirements.player.finishing.scope1": "Finishing Max: %1",
  "academy.requirements.player.finishing.scope2": "Finishing Exactly: %1",
  "academy.requirements.player.fk_acc.scope0": "FK Acc. Min: %1",
  "academy.requirements.player.fk_acc.scope1": "FK Acc. Max: %1",
  "academy.requirements.player.fk_acc.scope2": "FK Acc. Exactly: %1",
  "academy.requirements.player.heading_acc.scope0": "Heading Acc. Min: %1",
  "academy.requirements.player.heading_acc.scope1": "Heading Acc. Max: %1",
  "academy.requirements.player.heading_acc.scope2": "Heading Acc. Exactly: %1",
  "academy.requirements.player.icon-traits-count.scope0":
    "# of PlayStyle+ Min: %1",
  "academy.requirements.player.icon-traits-count.scope1":
    "# of PlayStyle+ Max: %1",
  "academy.requirements.player.icon-traits-count.scope2":
    "# of PlayStyle+ Equals: %1",
  "academy.requirements.player.icon-traits-exists": "PlayStyle+: %1",
  "academy.requirements.player.interceptions.scope0": "Interceptions Min: %1",
  "academy.requirements.player.interceptions.scope1": "Interceptions Max: %1",
  "academy.requirements.player.interceptions.scope2":
    "Interceptions Exactly: %1",
  "academy.requirements.player.jumping.scope0": "Jumping Min: %1",
  "academy.requirements.player.jumping.scope1": "Jumping Max: %1",
  "academy.requirements.player.jumping.scope2": "Jumping Exactly: %1",
  "academy.requirements.player.league.label": "League:",
  "academy.requirements.player.long_passing.scope0": "Long Passing Min: %1",
  "academy.requirements.player.long_passing.scope1": "Long Passing Max: %1",
  "academy.requirements.player.long_passing.scope2": "Long Passing Exactly: %1",
  "academy.requirements.player.long_shots.scope0": "Long Shots Min: %1",
  "academy.requirements.player.long_shots.scope1": "Long Shots Max: %1",
  "academy.requirements.player.long_shots.scope2": "Long Shots Exactly: %1",
  "academy.requirements.player.nation.label": "Nation:",
  "academy.requirements.player.ovr.scope0": "OVR Min: %1",
  "academy.requirements.player.ovr.scope1": "OVR Max: %1",
  "academy.requirements.player.ovr.scope2": "OVR Exactly: %1",
  "academy.requirements.player.pace.scope0": "Pace Min: %1",
  "academy.requirements.player.pace.scope1": "Pace Max: %1",
  "academy.requirements.player.pace.scope2": "Pace Exactly: %1",
  "academy.requirements.player.passing.scope0": "Passing Min: %1",
  "academy.requirements.player.passing.scope1": "Passing Max: %1",
  "academy.requirements.player.passing.scope2": "Passing Exactly: %1",
  "academy.requirements.player.penalties.scope0": "Penalties Min: %1",
  "academy.requirements.player.penalties.scope1": "Penalties Max: %1",
  "academy.requirements.player.penalties.scope2": "Penalties Exactly: %1",
  "academy.requirements.player.physicality.scope0": "Physicality Min: %1",
  "academy.requirements.player.physicality.scope1": "Physicality Max: %1",
  "academy.requirements.player.physicality.scope2": "Physicality Exactly: %1",
  "academy.requirements.player.positioning.scope0": "Positioning Min: %1",
  "academy.requirements.player.positioning.scope1": "Positioning Max: %1",
  "academy.requirements.player.positioning.scope2": "Positioning Exactly: %1",
  "academy.requirements.player.reactions.scope0": "Reactions Min: %1",
  "academy.requirements.player.reactions.scope1": "Reactions Max: %1",
  "academy.requirements.player.reactions.scope2": "Reactions Exactly: %1",
  "academy.requirements.player.shooting.scope0": "Shooting Min: %1",
  "academy.requirements.player.shooting.scope1": "Shooting Max: %1",
  "academy.requirements.player.shooting.scope2": "Shooting Exactly: %1",
  "academy.requirements.player.short_passing.scope0": "Short Passing Min: %1",
  "academy.requirements.player.short_passing.scope1": "Short Passing Max: %1",
  "academy.requirements.player.short_passing.scope2":
    "Short Passing Exactly: %1",
  "academy.requirements.player.shot_power.scope0": "Shot Power Min: %1",
  "academy.requirements.player.shot_power.scope1": "Shot Power Max: %1",
  "academy.requirements.player.shot_power.scope2": "Shot Power Exactly: %1",
  "academy.requirements.player.skill_moves.scope0": "Skill Moves Min: %1",
  "academy.requirements.player.skill_moves.scope1": "Skill Moves Max: %1",
  "academy.requirements.player.skill_moves.scope2": "Skill Moves Exactly: %1",
  "academy.requirements.player.sliding_tackle.scope0": "Sliding Tackle Min: %1",
  "academy.requirements.player.sliding_tackle.scope1": "Sliding Tackle Max: %1",
  "academy.requirements.player.sliding_tackle.scope2":
    "Sliding Tackle Exactly: %1",
  "academy.requirements.player.sprint_speed.scope0": "Sprint Speed Min: %1",
  "academy.requirements.player.sprint_speed.scope1": "Sprint Speed Max: %1",
  "academy.requirements.player.sprint_speed.scope2": "Sprint Speed Exactly: %1",
  "academy.requirements.player.stamina.scope0": "Stamina Min: %1",
  "academy.requirements.player.stamina.scope1": "Stamina Max: %1",
  "academy.requirements.player.stamina.scope2": "Stamina Exactly: %1",
  "academy.requirements.player.standing_tackle.scope0":
    "Standing Tackle Min: %1",
  "academy.requirements.player.standing_tackle.scope1":
    "Standing Tackle Max: %1",
  "academy.requirements.player.standing_tackle.scope2":
    "Standing Tackle Exactly: %1",
  "academy.requirements.player.strength.scope0": "Strength Min: %1",
  "academy.requirements.player.strength.scope1": "Strength Max: %1",
  "academy.requirements.player.strength.scope2": "Strength Exactly: %1",
  "academy.requirements.player.total-traits-count.scope0":
    "# of Total PlayStyles Min: %1",
  "academy.requirements.player.total-traits-count.scope1":
    "# of Total PlayStyles Max: %1",
  "academy.requirements.player.total-traits-count.scope2":
    "# of Total PlayStyles Equals: %1",
  "academy.requirements.player.vision.scope0": "Vision Min: %1",
  "academy.requirements.player.vision.scope1": "Vision Max: %1",
  "academy.requirements.player.vision.scope2": "Vision Exactly: %1",
  "academy.requirements.player.volleys.scope0": "Volleys Min: %1",
  "academy.requirements.player.volleys.scope1": "Volleys Max: %1",
  "academy.requirements.player.volleys.scope2": "Volleys Exactly: %1",
  "academy.requirements.player.weak_foot.scope0": "Weak Foot Min: %1",
  "academy.requirements.player.weak_foot.scope1": "Weak Foot Max: %1",
  "academy.requirements.player.weak_foot.scope2": "Weak Foot Exactly: %1",
  "academy.requirements.position.not.scope2": "Must not be:",
  "academy.requirements.position.scope2": "Position:",
  "academy.requirements.rarity.not.scope2": "Not Rarity:",
  "academy.requirements.rarity.scope2": "Rarity:",
  "academy.requirements.tradable": "Tradeable",
  "academy.requirements.untradable": "Untradeable",
};

export const RARITY_GROUP = {
  Player_Group_11: "TOTW, TOTS or FUT Champions",
  Player_Group_12: "TOTW, TOTS or FUT Champions",
  Player_Group_18: "TOTW, TOTS",
  Player_Group_27: "Any TOTS or TOTW",
  Player_Group_28: "Any TOTS (Except UT Champions)",
  Player_Group_4: "Rare",
  Player_Group_5: "UCL Common or Rare",
  Player_Group_6: "UEFA Champions League Common or Rare",
  Player_Group_7: "RTTK",
  Player_Group_9: "Rare",
};

export const RARITY = {
  "item.raretype0": "Common",
  "item.raretype1": "Rare",
  "item.raretype10": "Pro Player",
  "item.raretype100": "Vintage",
  "item.raretype101": "Premium",
  "item.raretype102": "Ultimate",
  "item.raretype103": "UECL POTM",
  "item.raretype104": "Team of the Season Plus",
  "item.raretype105": "UECL Road to the Knockouts",
  "item.raretype106": "FUT VIP",
  "item.raretype107": "adidas NUMBERS UP",
  "item.raretype108": "FC Versus Ice",
  "item.raretype11": "Team of the Season",
  "item.raretype110": "SIGNATURE SIGNINGS",
  "item.raretype111": "Fantasy FC",
  "item.raretype113": "Special Item",
  "item.raretype114": "POTM SERIE A",
  "item.raretype115": "POTM EREDIVISIE",
  "item.raretype116": "FC Versus Fire",
  "item.raretype117": "Winter Wildcards",
  "item.raretype118": "WINTER WILDCARDS",
  "item.raretype119": "WILDCARD REWARD",
  "item.raretype12": "Icon",
  "item.raretype120": "WILDCARD TOKEN",
  "item.raretype121": "Standard",
  "item.raretype122": "Legendary",
  "item.raretype123": "UEFA EURO 2024 Player",
  "item.raretype124": "UCL Road to the Final",
  "item.raretype125": "UEL Road to the Final",
  "item.raretype126": "UECL Road to the Final",
  "item.raretype127": "TOTS Champions",
  "item.raretype128": "World Cup Player",
  "item.raretype129": "World Cup Icon",
  "item.raretype13": "Complete Founder Evolution",
  "item.raretype130": "FIFA WC Path To Glory",
  "item.raretype131": "World Cup Star",
  "item.raretype132": "World Cup Swap Token",
  "item.raretype133": "World Cup Hero",
  "item.raretype134": "FANTASY FUT",
  "item.raretype135": "Fantasy FC Hero",
  "item.raretype138": "World Cup Team of the Tournament",
  "item.raretype139": "Road to World Cup",
  "item.raretype14": "UWCL Team of the Group Stage",
  "item.raretype145": "World Cup Stories",
  "item.raretype146": "World Cup Phenoms",
  "item.raretype148": "World Cup Showdown",
  "item.raretype149": "Ultimate Birthday ICON",
  "item.raretype15": "In-Progress Founder Evolution",
  "item.raretype150": "Dynamic Duos",
  "item.raretype151": "Centurions",
  "item.raretype152": "FUT BIRTHDAY ICON",
  "item.raretype153": "Shapeshifters ICON",
  "item.raretype154": "FUTTIES Heroes",
  "item.raretype155": "TOTY ICON",
  "item.raretype156": "HISTORY MAKERS",
  "item.raretype157": "Thunderstruck ICON",
  "item.raretype158": "FIFA WORLD CUP™",
  "item.raretype159": "FUT Champions TOTS",
  "item.raretype16": "UT Winner",
  "item.raretype160": "GOLAZO Hero",
  "item.raretype161": "GOLAZO ICON",
  "item.raretype162": "FUT Birthday Swaps Token",
  "item.raretype163": "FUT Ballers",
  "item.raretype164": "Premium FUTTIES",
  "item.raretype165": "Premium Level Up",
  "item.raretype166": "PREMIUM SHAPESHIFTERS",
  "item.raretype167": "Level Up",
  "item.raretype168": "Centurions ICON",
  "item.raretype17": "Ultimate Team Champions Pro",
  "item.raretype170": "Future Stars ICON",
  "item.raretype171": "UEFA Heroes (Mens)",
  "item.raretype172": "UEFA Heroes (Womens)",
  "item.raretype18": "UT Champions",
  "item.raretype180": "UEFA Euro Path to Glory",
  "item.raretype181": "UEFA Euro Road to the Cup",
  "item.raretype182": "Nike",
  "item.raretype19": "Ultimate Team Champions",
  "item.raretype2": "Locked Items",
  "item.raretype20": "International Man of the Match",
  "item.raretype21": "Ones to Watch",
  "item.raretype22": "Trailblazers",
  "item.raretype23": "Radioactive EVO",
  "item.raretype24": "Squad Building Challenge",
  "item.raretype25": "End Of An Era",
  "item.raretype26": "Centurions Evo In-Progress",
  "item.raretype27": "Centurions Evo",
  "item.raretype28": "Triple Threat",
  "item.raretype3": "Team of the Week",
  "item.raretype30": "Ultimate Birthday",
  "item.raretype31": "UWCL Road to the Knockouts",
  "item.raretype32": "UWCL Road to the Final",
  "item.raretype33": "Thunderstruck",
  "item.raretype34": "FC Pro Live",
  "item.raretype35": "Winter Wildcards ICON",
  "item.raretype36": "Live Team of the Season",
  "item.raretype37": "Radioactive",
  "item.raretype38": "Special Item",
  "item.raretype39": "Special Item",
  "item.raretype4": "Match Winner",
  "item.raretype40": "Special Item",
  "item.raretype41": "Special Item",
  "item.raretype42": "POTM Bundesliga",
  "item.raretype43": "POTM Premier League",
  "item.raretype44": "Special Item",
  "item.raretype45": "UEFA Europa League POTM",
  "item.raretype46": "UEL Road to the Knockouts",
  "item.raretype47": "Winter Wildcards Evo",
  "item.raretype48": "Team of the Season Plus",
  "item.raretype49": "UEFA Champions League POTM",
  "item.raretype5": "Team of the Year",
  "item.raretype50": "UCL Road to the Knockouts",
  "item.raretype51": "Flashback Player",
  "item.raretype52": "CONMEBOL Sudamericana",
  "item.raretype53": "CONMEBOL Libertadores",
  "item.raretype54": "CONMEBOL Libertadores MOTM",
  "item.raretype55": "In-Progress Future Stars Evolution",
  "item.raretype56": "Select",
  "item.raretype57": "Showdown Plus",
  "item.raretype58": "SHOWDOWN",
  "item.raretype59": "CONMEBOL Sudamericana MOTM",
  "item.raretype6": "Record Breaker",
  "item.raretype60": "In-Progress Evolution",
  "item.raretype61": "Evolutions I",
  "item.raretype62": "Evolutions II",
  "item.raretype63": "Evolutions III",
  "item.raretype64": "TOTY Honourable Mentions",
  "item.raretype65": "TOTS Moments",
  "item.raretype66": "Future Stars Evo",
  "item.raretype67": "POTM MLS",
  "item.raretype68": "UEFA EUROPA LEAGUE TEAM OF THE TOURNAMENT",
  "item.raretype69": "UEL Team of the Group Stage",
  "item.raretype7": "Pundit Picks",
  "item.raretype70": "UEFA CHAMPIONS LEAGUE TEAM OF THE TOURNAMENT",
  "item.raretype71": "Future Stars",
  "item.raretype72": "UT Heroes",
  "item.raretype73": "Special Item",
  "item.raretype74": "Special Item",
  "item.raretype75": "NEXT LEVEL",
  "item.raretype76": "FGS SWAPS",
  "item.raretype77": "FGS SWAPS 2",
  "item.raretype78": "Special Item",
  "item.raretype79": "POTM Ligue 1",
  "item.raretype8": "Domestic Man of the Match",
  "item.raretype80": "FC Pro Open Jota",
  "item.raretype81": "Beats",
  "item.raretype82": "Triple Threat Heroes",
  "item.raretype83": "Special Item",
  "item.raretype85": "Ultimate Dynasties ICON",
  "item.raretype86": "POTM LALIGA EA SPORTS",
  "item.raretype87": "SQUAD FOUNDATIONS",
  "item.raretype870": "Special Item",
  "item.raretype88": "In-Progress Ultimate Birthday Evolution",
  "item.raretype89": "Ultimate Birthday Evo",
  "item.raretype9": "UT TBD",
  "item.raretype90": "Moments",
  "item.raretype91": "Storyline",
  "item.raretype92": "TOTS In-Progress EVO",
  "item.raretype93": "TOTS Champions Plus",
  "item.raretype94": "Special Item",
  "item.raretype95": "Ultimate Dynasties",
  "item.raretype95A": "HEADLINERS",
  "item.raretype96": "HEADLINERS STREAK",
  "item.raretype97": "Out of Position",
  "item.raretype98": "LEAGUE PLAYER",
  "item.raretype99": "Epic",
  "item.rraretype65": "TEAM OF THE SEASON MOMENTS",
  "item.rraretype67": "POTM MLS",
};
